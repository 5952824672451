import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { getCompanyTheme, getCompanySloganTheme } from '../../utils/company';
import { matrixAction } from '../../redux/actions/authActions';

const useStyles = makeStyles((theme) => ({
  root: {
    '& p': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '15px',
      lineHeight: '19px',
      color: '#484848',
    },
    '& h2': {
      borderLeft: '4px solid #f9b257',
      paddingLeft: '16px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '22px',
      lineHeight: '29px',
      color: '#484848',
      marginBottom: '35px',
    },
  },
}));

const CompanyDetailsFlex = ({ slogan, children, companyName }) => {
  const classes = useStyles();
  const color = getCompanyTheme(companyName);
  const colorSlogan = getCompanySloganTheme(companyName);
  const leftBorder = color && { borderLeft: `4px solid ${color}`, color: `${colorSlogan}` };
  
  matrixAction('LANDING_PAGE');

  return (
    <div className={classes.root}>
      <h2 style={leftBorder}>{slogan}</h2>
      <div>{children}</div>
    </div>
  );
};

export default CompanyDetailsFlex;
