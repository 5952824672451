import React, { useMemo, useState } from "react"
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { BASE_API_URL } from '../../utils/constants';

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

const Home = () => {
    const [isFetching, setIsFetching] = useState(false);
    const [snapshots, setSnapshots] = useState([]);
    const [questionnaires, setQuestionnaires] = useState([]);
    const [trackingLogins, setTrackingLogins] = useState([]);
    const [trackingLibraryLoadingTime, setTrackingLibraryLoadingTime] = useState([]);
    const [trackingSessionDownloadTime, setTrackingSessionDownloadTime] = useState([]);
    const [metrics, setMetrics] = useState([]);

    const SNAPSHOT_URL = BASE_API_URL + "/rhythmedic-snapshot";
    const QUESTIONNAIRE_URL = BASE_API_URL + "/rhythmedic-questionnaire";
    const TRACKING_LOGIN_URL = BASE_API_URL + "/account/tracking";
    const TRACKING_URL = BASE_API_URL + "/account/tracking-type";
    const METRICS_URL = BASE_API_URL + "/matrix";

    const snapshotColumns = [
        {
            field: 'group',
            headerName: 'Group',
            sortable: true,
            width: 200
        },
        {
            field: 'phase',
            headerName: 'Phase',
            sortable: true,
            width: 150
        },
        {
            field: 'sid',
            headerName: 'SID',
            sortable: true,
            width: 150
        },
        {
            field: 'snapshot_uuid_pre',
            headerName: 'Snapshot UUID',
            sortable: true,
            width: 300
        },
        {
            field: 'snapshot_start_pre',
            headerName: 'Start time',
            sortable: true,
            width: 150
        },
        {
            field: 'snapshot_finish_pre',
            headerName: 'End time',
            sortable: true,
            width: 150
        },
        {
            field: 'heartrate_pre',
            headerName: 'Heartrate',
            sortable: true,
            width: 100
        },
        {
            field: 'hrv_low_freq_sum_pre',
            headerName: 'HRV Low Frequence Sum',
            sortable: true,
            width: 100
        },
        {
            field: 'hrv_high_freq_sum_pre',
            headerName: 'HRV High Frequence Sum',
            sortable: true,
            width: 100
        },
        {
            field: 'hrv_score_pre',
            headerName: 'HRV Score',
            sortable: true,
            width: 100
        },
        {
            field: 'reported_stress_pre',
            headerName: 'Reported stress',
            sortable: true,
            width: 100
        },
        {
            field: 'computer_vision_stress_pre',
            headerName: 'Computer vision stress',
            sortable: true,
            width: 100
        }
    ];

    const questionnaireColumns = [
        {
            field: 'group',
            headerName: 'Group',
            sortable: true,
            width: 200
        },
        {
            field: 'phase',
            headerName: 'Phase',
            sortable: true,
            width: 200
        },
        {
            field: 'sid',
            headerName: 'SID',
            sortable: true,
            width: 200
        },
        {
            field: 'key',
            headerName: 'Key',
            sortable: true,
            width: 200
        },
        {
            field: 'questionnaire',
            headerName: 'Data',
            sortable: true,
            width: 600
        },
        {
            field: 'score',
            headerName: 'Score',
            sortable: true,
            width: 200
        },
        {
            field: 'dateTime',
            headerName: 'Date time',
            sortable: true,
            width: 200
        }
    ]

    const trackingLoginColumns = [
        {
            field: 'email',
            headerName: 'Email',
            sortable: true,
            width: 500
        },
        {
            field: 'startTime',
            headerName: 'Start time',
            sortable: true,
            width: 300
        },
        {
            field: 'successfulTime',
            headerName: 'Successful Time',
            sortable: true,
            width: 300
        },
        {
            field: 'offsetTime',
            headerName: 'Offset',
            sortable: true,
            width: 300
        },
        {
            field: 'duration',
            headerName: 'Duration',
            sortable: true,
            width: 300
        }
    ]

    const trackingLibraryLoadingTimeColumns = [
        {
            field: 'userId',
            headerName: 'USER UUID',
            sortable: true,
            width: 400
        },
        {
            field: 'startTime',
            headerName: 'Start time',
            sortable: true,
            width: 300
        },
        {
            field: 'successfulTime',
            headerName: 'Successful Time',
            sortable: true,
            width: 300
        },
        {
            field: 'offsetTime',
            headerName: 'Offset',
            sortable: true,
            width: 300
        },
        {
            field: 'duration',
            headerName: 'Duration',
            sortable: true,
            width: 300
        }
    ]

    const trackingSessionDownloadTimeColumns = [
        {
            field: 'userId',
            headerName: 'USER UUID',
            sortable: true,
            width: 400
        },
        {
            field: 'startTime',
            headerName: 'Start time',
            sortable: true,
            width: 300
        },
        {
            field: 'successfulTime',
            headerName: 'Successful Time',
            sortable: true,
            width: 300
        },
        {
            field: 'offsetTime',
            headerName: 'Offset',
            sortable: true,
            width: 150
        },
        {
            field: 'duration',
            headerName: 'Duration',
            sortable: true,
            width: 150
        },
        {
            field: 'screen',
            headerName: 'Screen',
            sortable: true,
            width: 300
        }
    ]

    const metricsColumns = [
        {
            field: 'type',
            headerName: 'Action',
            sortable: true,
            width: 500
        },
        {
            field: 'step',
            headerName: 'Step',
            sortable: true,
            width: 300
        },
        {
            field: 'count',
            headerName: 'Number',
            sortable: true,
            width: 300
        }
    ]

    const getAllSnapshots = async () => {
        const token = localStorage.getItem("auth");
        const resSnapshot = await axios.get(SNAPSHOT_URL, {}, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });
        if (resSnapshot.status === 200 && resSnapshot.data) {
            let data = resSnapshot.data;
            for (let i = 0; i < data.length; i++) {
                data[i].id = i;
            }
            setSnapshots(data);
        } else {
            toast.error("GET SNAPSHOT FAILED")
        }
    }

    const getAllQuestionnaires = async () => {
        const token = localStorage.getItem("auth");
        const resQuestionnaire = await axios.get(QUESTIONNAIRE_URL, {}, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });
        if (resQuestionnaire.status === 200 && resQuestionnaire.data) {
            let data = resQuestionnaire.data;
            for (let i = 0; i < data.length; i++) {
                data[i].id = i;
            }
            setQuestionnaires(data);
        } else {
            toast.error("GET QUESTIONNAIRE FAILED")
        }
    }

    const getTrackingLogin = async () => {
        const token = localStorage.getItem("auth");
        const res = await axios.get(TRACKING_LOGIN_URL, {}, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });
        if (res.status === 200 && res.data) {
            let data = res.data;
            for (let i = 0; i < data.length; i++) {
                data[i].id = i;
            }
            setTrackingLogins(data);
        } else {
            toast.error("GET TRACKING LOGIN FAILED")
        }
    }

    const getTrackingLibraryLoadingTime = async () => {
        const token = localStorage.getItem("auth");
        const res = await axios.get(TRACKING_URL + "?type=LIBRARY_LOADING_TIME", {}, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });
        if (res.status === 200 && res.data) {
            let data = res.data;
            for (let i = 0; i < data.length; i++) {
                data[i].id = i;
            }
            setTrackingLibraryLoadingTime(data);
        } else {
            toast.error("GET TRACKING LIBRARY LOADING TIME FAILED")
        }
    }

    const getTrackingSessionDownloadTime = async () => {
        const token = localStorage.getItem("auth");
        const res = await axios.get(TRACKING_URL + "?type=SESSION_DOWNLOAD_TIME", {}, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });
        if (res.status === 200 && res.data) {
            let data = res.data;
            for (let i = 0; i < data.length; i++) {
                data[i].id = i;
            }
            setTrackingSessionDownloadTime(data);
        } else {
            toast.error("GET TRACKING SESSION DOWNLOAD TIME FAILED")
        }
    }

    const getMetrics = async () => {
        const token = localStorage.getItem("auth");
        const res = await axios.get(METRICS_URL, {}, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });
        if (res.status === 200 && res.data) {
            let data = res.data;
            for (let i = 0; i < data.length; i++) {
                data[i].id = i;
            }
            setMetrics(data);
        } else {
            toast.error("GET METRICS FAILED")
        }
    }

    useMemo(() => {
        const token = localStorage.getItem("auth");
        let time = Date.now();
        const lastTimeLogin = + localStorage.getItem("time");

        if (!token || time - lastTimeLogin > 60 * 60 * 1000) {
            localStorage.removeItem("auth");
            window.location.href = "/apidata";
        } else {
            getAllSnapshots();
            getAllQuestionnaires();
            getTrackingLogin();
            getTrackingLibraryLoadingTime();
            getTrackingSessionDownloadTime();
            getMetrics();
        }
    }, []);

    if (isFetching) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <div className="home">
            <br></br>
            <div style={{ textAlign: 'center' }}> SNAPSHOTS </div>
            <br></br>
            <div style={{ height: 500, width: '100%' }} className="d-grid gap-2 mt-3 justify-center">
                <DataGrid
                    rows={snapshots}
                    columns={snapshotColumns}
                    initialState={{}}
                    slots={{
                        toolbar: CustomToolbar,
                    }} />
            </div>
            <br></br>
            <div style={{ textAlign: 'center' }}> QUESTIONNAIRES </div>
            <br></br>
            <div style={{ height: 500, width: '100%' }} className="d-grid gap-2 mt-3 justify-center">
                <DataGrid
                    rows={questionnaires}
                    columns={questionnaireColumns}
                    initialState={{}}
                    slots={{
                        toolbar: CustomToolbar,
                    }} />
            </div>
            <br></br>
            <div style={{ textAlign: 'center' }}> TRACKING LOGIN </div>
            <br></br>
            <div style={{ height: 500, width: '100%' }} className="d-grid gap-2 mt-3 justify-center">
                <DataGrid
                    rows={trackingLogins}
                    columns={trackingLoginColumns}
                    initialState={{}}
                    slots={{
                        toolbar: CustomToolbar,
                    }} />
            </div>
            <br></br>
            <div style={{ textAlign: 'center' }}> TRACKING LIBRARY LOADING TIME </div>
            <br></br>
            <div style={{ height: 500, width: '100%' }} className="d-grid gap-2 mt-3 justify-center">
                <DataGrid
                    rows={trackingLibraryLoadingTime}
                    columns={trackingLibraryLoadingTimeColumns}
                    initialState={{}}
                    slots={{
                        toolbar: CustomToolbar,
                    }} />
            </div>
            <br></br>
            <div style={{ textAlign: 'center' }}> TRACKING DOWNLOAD SESSION TIME </div>
            <br></br>
            <div style={{ height: 500, width: '100%' }} className="d-grid gap-2 mt-3 justify-center">
                <DataGrid
                    rows={trackingSessionDownloadTime}
                    columns={trackingSessionDownloadTimeColumns}
                    initialState={{}}
                    slots={{
                        toolbar: CustomToolbar,
                    }} />
            </div>
            <br></br>
            <div style={{ textAlign: 'center' }}> Metrics </div>
            <br></br>
            <div style={{ height: 500, width: '100%' }} className="d-grid gap-2 mt-3 justify-center">
                <DataGrid
                    rows={metrics}
                    columns={metricsColumns}
                    initialState={{}}
                    slots={{
                        toolbar: CustomToolbar,
                    }} />
            </div>
            <br></br>
            <ToastContainer />
        </div>
    )
}
export default Home;
