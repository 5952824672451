import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ImdLandingPage from './pages/imd/landing-page/landingpage';
import ImdSignupPage from './pages/imd/signup-page/signup-page';
import ImdSigninPage from './pages/imd/signin-page/signin-page';
import ImdCartPage from './pages/imd/cart-page/cart-page';

import WfLandingPage from './pages/wildflowers/landing-page/landingpage';
import WfSigninPage from './pages/wildflowers/signin-page/signin-page';
import WfCartPage from './pages/wildflowers/cart-page/cart-page';

import MsLandingPage from './pages/miindset/landing-page/landingpage';
import MsSignupPage from './pages/miindset/signup-page/signup-page';
import MsSigninPage from './pages/miindset/signin-page/signin-page';
import MsCartPage from './pages/miindset/cart-page/cart-page';

import GtLandingPage from './pages/genuine-tea/landing-page/landingpage';
import GtSignupPage from './pages/genuine-tea/signup-page/signup-page';
import GtSigninPage from './pages/genuine-tea/signin-page/signin-page';
import GtCartPage from './pages/genuine-tea/cart-page/cart-page';

import CULandingPage from './pages/cheoubc/landing-page/landingpage';
import CUSignupPage from './pages/cheoubc/signup-page/signup-page';
import CUSigninPage from './pages/cheoubc/signin-page/signin-page';
import CUCartPage from './pages/cheoubc/cart-page/cart-page';

import SYNLandingPage from './pages/synaptitude/landing-page/landingpage';
import SYNSignupPage from './pages/synaptitude/signup-page/signup-page';
import SYNSigninPage from './pages/synaptitude/signin-page/signin-page';
import SYNCartPage from './pages/synaptitude/cart-page/cart-page';

import IBMLandingPage from './pages/ibm/landing-page/landingpage';
import IBMSignupPage from './pages/ibm/signup-page/signup-page';
import IBMSigninPage from './pages/ibm/signin-page/signin-page';
import IBMCartPage from './pages/ibm/cart-page/cart-page';

import CAMHLandingPage from './pages/camh/landing-page/landingpage';
import CAMHSignupPage from './pages/camh/signup-page/signup-page';
import CAMHSigninPage from './pages/camh/signin-page/signin-page';
import CAMHCartPage from './pages/camh/cart-page/cart-page';

import PCCTLandingPage from './pages/pcct/landing-page/landingpage';
import PCCTSignupPage from './pages/pcct/signup-page/signup-page';
import PCCTSigninPage from './pages/pcct/signin-page/signin-page';
import PCCTCartPage from './pages/pcct/cart-page/cart-page';

import KLandingPage from './pages/kinduct/landing-page/landingpage';
import KSignupPage from './pages/kinduct/signup-page/signup-page';
import KSigninPage from './pages/kinduct/signin-page/signin-page';
import KCartPage from './pages/kinduct/cart-page/cart-page';

import ASLandingPage from './pages/appscript/landing-page/landingpage';
import ASSignupPage from './pages/appscript/signup-page/signup-page';
import ASSigninPage from './pages/appscript/signin-page/signin-page';
import ASCartPage from './pages/appscript/cart-page/cart-page';
import ASCheckoutPage from './pages/appscript/checkout-page/checkout-page';
import ASSuccessPage from './pages/appscript/success-page/success-page';

import MGLandingPage from './pages/math-guru/landing-page/landingpage';
import MGSignupPage from './pages/math-guru/signup-page/signup-page';
import MGSigninPage from './pages/math-guru/signin-page/signin-page';
import MGCartPage from './pages/math-guru/cart-page/cart-page';
import MGCheckoutPage from './pages/math-guru/checkout-page/checkout-page';
import MGSuccessPage from './pages/math-guru/success-page/success-page';

import PCHLandingPage from './pages/pchealth/landing-page/landingpage';
import PCHSignupPage from './pages/pchealth/signup-page/signup-page';
import PCHSigninPage from './pages/pchealth/signin-page/signin-page';
import PCHCartPage from './pages/pchealth/cart-page/cart-page';
import PCHCheckoutPage from './pages/pchealth/checkout-page/checkout-page';
import PCHSuccessPage from './pages/pchealth/success-page/success-page';

import SHLandingPage from './pages/synaptichacks/landing-page/landingpage';
import SHSignupPage from './pages/synaptichacks/signup-page/signup-page';
import SHSigninPage from './pages/synaptichacks/signin-page/signin-page';
import SHCartPage from './pages/synaptichacks/cart-page/cart-page';
import SHCheckoutPage from './pages/synaptichacks/checkout-page/checkout-page';
import SHSuccessPage from './pages/synaptichacks/success-page/success-page';

import AMLandingPage from './pages/amdtx/landing-page/landingpage';
import AMSignupPage from './pages/amdtx/signup-page/signup-page';
import AMSigninPage from './pages/amdtx/signin-page/signin-page';
import AMCartPage from './pages/amdtx/cart-page/cart-page';
import AMCheckoutPage from './pages/amdtx/checkout-page/checkout-page';
import AMSuccessPage from './pages/amdtx/success-page/success-page';

import MJLandingPage from './pages/mindfuljunkie/landing-page/landingpage';
import MJSignupPage from './pages/mindfuljunkie/signup-page/signup-page';
import MJSigninPage from './pages/mindfuljunkie/signin-page/signin-page';
import MJCartPage from './pages/mindfuljunkie/cart-page/cart-page';
import MJCheckoutPage from './pages/mindfuljunkie/checkout-page/checkout-page';
import MJSuccessPage from './pages/mindfuljunkie/success-page/success-page';

import MBLandingPage from './pages/manonbreton/landing-page/landingpage';
import MBSignupPage from './pages/manonbreton/signup-page/signup-page';
import MBSigninPage from './pages/manonbreton/signin-page/signin-page';
import MBCartPage from './pages/manonbreton/cart-page/cart-page';
import MBCheckoutPage from './pages/manonbreton/checkout-page/checkout-page';
import MBSuccessPage from './pages/manonbreton/success-page/success-page';

import JLLandingPage from './pages/julienlacaille/landing-page/landingpage';
import JLSignupPage from './pages/julienlacaille/signup-page/signup-page';
import JLSigninPage from './pages/julienlacaille/signin-page/signin-page';
import JLCartPage from './pages/julienlacaille/cart-page/cart-page';

import LDLandingPage from './pages/lifedrop/landing-page/landingpage';
import LDSignupPage from './pages/lifedrop/signup-page/signup-page';
import LDSigninPage from './pages/lifedrop/signin-page/signin-page';
import LDCartPage from './pages/lifedrop/cart-page/cart-page';

import GSLandingPage from './pages/gspace/landing-page/landingpage';
import GSSignupPage from './pages/gspace/signup-page/signup-page';
import GSSigninPage from './pages/gspace/signin-page/signin-page';
import GSCartPage from './pages/gspace/cart-page/cart-page';
import GSCheckoutPage from './pages/gspace/checkout-page/checkout-page';
import GSSuccessPage from './pages/gspace/success-page/success-page';

import LLandingPage from './pages/lumino/landing-page/landingpage';
import LSignupPage from './pages/lumino/signup-page/signup-page';
import LSigninPage from './pages/lumino/signin-page/signin-page';
import LCartPage from './pages/lumino/cart-page/cart-page';
import LCheckoutPage from './pages/lumino/checkout-page/checkout-page';
import LSuccessPage from './pages/lumino/success-page/success-page';

import SLLandingPage from './pages/sunlife/landing-page/landingpage';
import SLSignupPage from './pages/sunlife/signup-page/signup-page';
import SLSigninPage from './pages/sunlife/signin-page/signin-page';
import SLCartPage from './pages/sunlife/cart-page/cart-page';
import SLCheckoutPage from './pages/sunlife/checkout-page/checkout-page';
import SLSuccessPage from './pages/sunlife/success-page/success-page';

import GSCLandingPage from './pages/gsc/landing-page/landingpage';
import GSCSignupPage from './pages/gsc/signup-page/signup-page';
import GSCSigninPage from './pages/gsc/signin-page/signin-page';
import GSCCartPage from './pages/gsc/cart-page/cart-page';
import GSCCheckoutPage from './pages/gsc/checkout-page/checkout-page';
import GSCSuccessPage from './pages/gsc/success-page/success-page';

import CVMPCLandingPage from './pages/cvmpc/landing-page/landingpage';
import CVMPCSignupPage from './pages/cvmpc/signup-page/signup-page';
import CVMPCSigninPage from './pages/cvmpc/signin-page/signin-page';
import CVMPCCartPage from './pages/cvmpc/cart-page/cart-page';

import UoftLandingPage from './pages/uoft/landing-page/landingpage';
import UoftSignupPage from './pages/uoft/signup-page/signup-page';
import UoftSigninPage from './pages/uoft/signin-page/signin-page';
import UoftCartPage from './pages/uoft/cart-page/cart-page';
import UoftCheckoutPage from './pages/uoft/checkout-page/checkout-page';
import UoftSuccessPage from './pages/uoft/success-page/success-page';

import RhythmedicLandingPage from './pages/rhythmedic/landing-page/landingpage';
import RhythmedicSignupPage from './pages/rhythmedic/signup-page/signup-page';
import RhythmedicSigninPage from './pages/rhythmedic/signin-page/signin-page';
import RhythmedicCartPage from './pages/rhythmedic/cart-page/cart-page';
import RhythmedicCheckoutPage from './pages/rhythmedic/checkout-page/checkout-page';
import RhythmedicSuccessPage from './pages/rhythmedic/success-page/success-page';

import WindsorLandingPage from './pages/windsor/landing-page/landingpage';
import WindsorSignupPage from './pages/windsor/signup-page/signup-page';
import WindsorSigninPage from './pages/windsor/signin-page/signin-page';
import WindsorCartPage from './pages/windsor/cart-page/cart-page';
import WindsorCheckoutPage from './pages/windsor/checkout-page/checkout-page';
import WindsorSuccessPage from './pages/windsor/success-page/success-page';

import SeamlessLandingPage from './pages/seamless/landing-page/landingpage';
import SeamlessSignupPage from './pages/seamless/signup-page/signup-page';
import SeamlessSigninPage from './pages/seamless/signin-page/signin-page';
import SeamlessCartPage from './pages/seamless/cart-page/cart-page';
import SeamlessCheckoutPage from './pages/seamless/checkout-page/checkout-page';
import SeamlessSuccessPage from './pages/seamless/success-page/success-page';

import MindfulnessWorksLandingPage from './pages/mindfulnessworks/landing-page/landingpage';
import MindfulnessWorksSignupPage from './pages/mindfulnessworks/signup-page/signup-page';
import MindfulnessWorksSigninPage from './pages/mindfulnessworks/signin-page/signin-page';
import MindfulnessWorksCartPage from './pages/mindfulnessworks/cart-page/cart-page';

import StartupCareLandingPage from './pages/startupcare/landing-page/landingpage';
import StartupCareSignupPage from './pages/startupcare/signup-page/signup-page';
import StartupCareSigninPage from './pages/startupcare/signin-page/signin-page';
import StartupCareCartPage from './pages/startupcare/cart-page/cart-page';
import StartupCareCheckoutPage from './pages/startupcare/checkout-page/checkout-page';
import StartupCareSuccessPage from './pages/startupcare/success-page/success-page';

import SdmLandingPage from './pages/sdm/landing-page/landingpage';
import SdmSignupPage from './pages/sdm/signup-page/signup-page';
import SdmSigninPage from './pages/sdm/signin-page/signin-page';
import SdmCartPage from './pages/sdm/cart-page/cart-page';
import SdmCheckoutPage from './pages/sdm/checkout-page/checkout-page';
import SdmSuccessPage from './pages/sdm/success-page/success-page';

import RstLandingPage from './pages/rst/landing-page/landingpage';
import RstSignupPage from './pages/rst/signup-page/signup-page';
import RstSigninPage from './pages/rst/signin-page/signin-page';
import RstCartPage from './pages/rst/cart-page/cart-page';
import RstCheckoutPage from './pages/rst/checkout-page/checkout-page';
import RstSuccessPage from './pages/rst/success-page/success-page';

import MindfulnessLandingPage from './pages/mindfulness/landing-page/landingpage';
import MindfulnessSignupPage from './pages/mindfulness/signup-page/signup-page';
import MindfulnessSigninPage from './pages/mindfulness/signin-page/signin-page';
import MindfulnessCartPage from './pages/mindfulness/cart-page/cart-page';
import MindfulnessCheckoutPage from './pages/mindfulness/checkout-page/checkout-page';
import MindfulnessSuccessPage from './pages/mindfulness/success-page/success-page';

import CarevoiceLandingPage from './pages/carevoice/landing-page/landingpage';
import CarevoiceSignupPage from './pages/carevoice/signup-page/signup-page';
import CarevoiceSigninPage from './pages/carevoice/signin-page/signin-page';
import CarevoiceCartPage from './pages/carevoice/cart-page/cart-page';

import MPCLandingPage from './pages/mpc/landing-page/landingpage';
import MPCSignupPage from './pages/mpc/signup-page/signup-page';
import MPCSigninPage from './pages/mpc/signin-page/signin-page';
import MPCCartPage from './pages/mpc/cart-page/cart-page';

import LeagueLandingPage from './pages/league/landing-page/landingpage';
import LeagueSignupPage from './pages/league/signup-page/signup-page';
import LeagueSigninPage from './pages/league/signin-page/signin-page';
import LeagueCartPage from './pages/league/cart-page/cart-page';
import LeagueCheckoutPage from './pages/league/checkout-page/checkout-page';
import LeagueSuccessPage from './pages/league/success-page/success-page';

import HealthaideLandingPage from './pages/healthaide/landing-page/landingpage';
import HealthaideSignupPage from './pages/healthaide/signup-page/signup-page';
import HealthaideSigninPage from './pages/healthaide/signin-page/signin-page';
import HealthaideCartPage from './pages/healthaide/cart-page/cart-page';
import HealthaideCheckoutPage from './pages/healthaide/checkout-page/checkout-page';
import HealthaideSuccessPage from './pages/healthaide/success-page/success-page';

import MyyogaLandingPage from './pages/myyoga/landing-page/landingpage';
import MyyogaSignupPage from './pages/myyoga/signup-page/signup-page';
import MyyogaSigninPage from './pages/myyoga/signin-page/signin-page';
import MyyogaCartPage from './pages/myyoga/cart-page/cart-page';
import MyyogaCheckoutPage from './pages/myyoga/checkout-page/checkout-page';
import MyyogaSuccessPage from './pages/myyoga/success-page/success-page';

import OpeninnovationLandingPage from './pages/openinnovation/landing-page/landingpage';
import OpeninnovationSignupPage from './pages/openinnovation/signup-page/signup-page';
import OpeninnovationSigninPage from './pages/openinnovation/signin-page/signin-page';
import OpeninnovationCartPage from './pages/openinnovation/cart-page/cart-page';
import OpeninnovationCheckoutPage from './pages/openinnovation/checkout-page/checkout-page';
import OpeninnovationSuccessPage from './pages/openinnovation/success-page/success-page';

import UrbanwellnessLandingPage from './pages/urbanwellness/landing-page/landingpage';
import UrbanwellnessSignupPage from './pages/urbanwellness/signup-page/signup-page';
import UrbanwellnessSigninPage from './pages/urbanwellness/signin-page/signin-page';
import UrbanwellnessCartPage from './pages/urbanwellness/cart-page/cart-page';
import UrbanwellnessCheckoutPage from './pages/urbanwellness/checkout-page/checkout-page';
import UrbanwellnessSuccessPage from './pages/urbanwellness/success-page/success-page';

import MindfulnessdubaiLandingPage from './pages/mindfulnessdubai/landing-page/landingpage';
import MindfulnessdubaiSignupPage from './pages/mindfulnessdubai/signup-page/signup-page';
import MindfulnessdubaiSigninPage from './pages/mindfulnessdubai/signin-page/signin-page';
import MindfulnessdubaiCartPage from './pages/mindfulnessdubai/cart-page/cart-page';
import MindfulnessdubaiCheckoutPage from './pages/mindfulnessdubai/checkout-page/checkout-page';
import MindfulnessdubaiSuccessPage from './pages/mindfulnessdubai/success-page/success-page';

import FriendsLandingPage from './pages/friends/landing-page/landingpage';
import FriendsSignupPage from './pages/friends/signup-page/signup-page';
import FriendsSigninPage from './pages/friends/signin-page/signin-page';
import FriendsCartPage from './pages/friends/cart-page/cart-page';
import FriendsCheckoutPage from './pages/friends/checkout-page/checkout-page';
import FriendsSuccessPage from './pages/friends/success-page/success-page';

import WeworkLandingPage from './pages/wework/landing-page/landingpage';
import WeworkSignupPage from './pages/wework/signup-page/signup-page';
import WeworkSigninPage from './pages/wework/signin-page/signin-page';
import WeworkCartPage from './pages/wework/cart-page/cart-page';
import WeworkCheckoutPage from './pages/wework/checkout-page/checkout-page';
import WeworkSuccessPage from './pages/wework/success-page/success-page';

import GanLandingPage from './pages/gan/landing-page/landingpage';
import GanSignupPage from './pages/gan/signup-page/signup-page';
import GanSigninPage from './pages/gan/signin-page/signin-page';
import GanCartPage from './pages/gan/cart-page/cart-page';
import GanCheckoutPage from './pages/gan/checkout-page/checkout-page';
import GanSuccessPage from './pages/gan/success-page/success-page';

import SosvLandingPage from './pages/sosv/landing-page/landingpage';
import SosvSignupPage from './pages/sosv/signup-page/signup-page';
import SosvSigninPage from './pages/sosv/signin-page/signin-page';
import SosvCartPage from './pages/sosv/cart-page/cart-page';
import SosvCheckoutPage from './pages/sosv/checkout-page/checkout-page';
import SosvSuccessPage from './pages/sosv/success-page/success-page';

import NewcampusLandingPage from './pages/newcampus/landing-page/landingpage';
import NewcampusSignupPage from './pages/newcampus/signup-page/signup-page';
import NewcampusSigninPage from './pages/newcampus/signin-page/signin-page';
import NewcampusCartPage from './pages/newcampus/cart-page/cart-page';
import NewcampusCheckoutPage from './pages/newcampus/checkout-page/checkout-page';
import NewcampusSuccessPage from './pages/newcampus/success-page/success-page';

import AchtsamengagiertLandingPage from './pages/achtsamengagiert/landing-page/landingpage';
import AchtsamengagiertSignupPage from './pages/achtsamengagiert/signup-page/signup-page';
import AchtsamengagiertSigninPage from './pages/achtsamengagiert/signin-page/signin-page';
import AchtsamengagiertCartPage from './pages/achtsamengagiert/cart-page/cart-page';
import AchtsamengagiertCheckoutPage from './pages/achtsamengagiert/checkout-page/checkout-page';
import AchtsamengagiertSuccessPage from './pages/achtsamengagiert/success-page/success-page';

import MccnLandingPage from './pages/mccn/landing-page/landingpage';
import MccnSignupPage from './pages/mccn/signup-page/signup-page';
import MccnSigninPage from './pages/mccn/signin-page/signin-page';
import MccnCartPage from './pages/mccn/cart-page/cart-page';
import MccnCheckoutPage from './pages/mccn/checkout-page/checkout-page';
import MccnSuccessPage from './pages/mccn/success-page/success-page';

import CvcarnivalLandingPage from './pages/cvcarnival/landing-page/landingpage';
import CvcarnivalSignupPage from './pages/cvcarnival/signup-page/signup-page';
import CvcarnivalSigninPage from './pages/cvcarnival/signin-page/signin-page';
import CvcarnivalCartPage from './pages/cvcarnival/cart-page/cart-page';
import CvcarnivalCheckoutPage from './pages/cvcarnival/checkout-page/checkout-page';
import CvcarnivalSuccessPage from './pages/cvcarnival/success-page/success-page';

import BuisconsultLandingPage from './pages/buisconsult/landing-page/landingpage';
import BuisconsultSignupPage from './pages/buisconsult/signup-page/signup-page';
import BuisconsultSigninPage from './pages/buisconsult/signin-page/signin-page';
import BuisconsultCartPage from './pages/buisconsult/cart-page/cart-page';
import BuisconsultCheckoutPage from './pages/buisconsult/checkout-page/checkout-page';
import BuisconsultSuccessPage from './pages/buisconsult/success-page/success-page';

import EclecticLandingPage from './pages/eclectic/landing-page/landingpage';
import EclecticSignupPage from './pages/eclectic/signup-page/signup-page';
import EclecticSigninPage from './pages/eclectic/signin-page/signin-page';
import EclecticCartPage from './pages/eclectic/cart-page/cart-page';
import EclecticCheckoutPage from './pages/eclectic/checkout-page/checkout-page';
import EclecticSuccessPage from './pages/eclectic/success-page/success-page';

import GlocoachLandingPage from './pages/glocoach/landing-page/landingpage';
import GlocoachSignupPage from './pages/glocoach/signup-page/signup-page';
import GlocoachSigninPage from './pages/glocoach/signin-page/signin-page';
import GlocoachCartPage from './pages/glocoach/cart-page/cart-page';
import GlocoachCheckoutPage from './pages/glocoach/checkout-page/checkout-page';
import GlocoachSuccessPage from './pages/glocoach/success-page/success-page';

import SNICLandingPage from './pages/snic/landing-page/landingpage';
import SNICSignupPage from './pages/snic/signup-page/signup-page';
import SNICSigninPage from './pages/snic/signin-page/signin-page';
import SNICCartPage from './pages/snic/cart-page/cart-page';
import SNICCheckoutPage from './pages/snic/checkout-page/checkout-page';
import SNICSuccessPage from './pages/snic/success-page/success-page';

import MbcsLandingPage from './pages/mbcs/landing-page/landingpage';
import MbcsSignupPage from './pages/mbcs/signup-page/signup-page';
import MbcsSigninPage from './pages/mbcs/signin-page/signin-page';
import MbcsCartPage from './pages/mbcs/cart-page/cart-page';
import MbcsCheckoutPage from './pages/mbcs/checkout-page/checkout-page';
import MbcsSuccessPage from './pages/mbcs/success-page/success-page';

import Cheot1dLandingPage from './pages/cheot1d/landing-page/landingpage';
import Cheot1dSignupPage from './pages/cheot1d/signup-page/signup-page';
import Cheot1dSigninPage from './pages/cheot1d/signin-page/signin-page';
import Cheot1dCartPage from './pages/cheot1d/cart-page/cart-page';

import MskccLandingPage from './pages/mskcc/landing-page/landingpage';
import MskccSignupPage from './pages/mskcc/signup-page/signup-page';
import MskccSigninPage from './pages/mskcc/signin-page/signin-page';
import MskccCartPage from './pages/mskcc/cart-page/cart-page';

import PtbiWestLandingPage from './pages/ptbiwest/landing-page/landingpage';
import PtbiWestSignupPage from './pages/ptbiwest/signup-page/signup-page';
import PtbiWestSigninPage from './pages/ptbiwest/signin-page/signin-page';
import PtbiWestCartPage from './pages/ptbiwest/cart-page/cart-page';

import ViplabLandingPage from './pages/viplab/landing-page/landingpage';
import ViplabSignupPage from './pages/viplab/signup-page/signup-page';
import ViplabSigninPage from './pages/viplab/signin-page/signin-page';
import ViplabCartPage from './pages/viplab/cart-page/cart-page';

import GroundzeroLandingPage from './pages/groundzero/landing-page/landingpage';
import GroundzeroSignupPage from './pages/groundzero/signup-page/signup-page';
import GroundzeroSigninPage from './pages/groundzero/signin-page/signin-page';
import GroundzeroCartPage from './pages/groundzero/cart-page/cart-page';

import OBILandingPage from './pages/obi/landing-page/landingpage';
import OBISignupPage from './pages/obi/signup-page/signup-page';
import OBISigninPage from './pages/obi/signin-page/signin-page';
import OBICartPage from './pages/obi/cart-page/cart-page';

import MstbiLandingPage from './pages/mstbi/landing-page/landingpage';
import MstbiSignupPage from './pages/mstbi/signup-page/signup-page';
import MstbiSigninPage from './pages/mstbi/signin-page/signin-page';
import MstbiCartPage from './pages/mstbi/cart-page/cart-page';

import GoogleCloseBrowser from './pages/google-close-browser/GoogleCloseBrowser';
// import './App.css';

import ApiDataPage from './pages/apidata/apidata';
import ApiDataDetailPage from './pages/apidata/apidata-detail';

import ChangePasswordPage from './pages/changepassword/changepassword';

function App() {
  return (
    <Switch>
      {/* IMD */}
      <Route exact path="/:company(imd)">
        <ImdLandingPage />
      </Route>
      <Route exact path="/:company(imd)/signup">
        <ImdSignupPage />
      </Route>
      <Route exact path="/:company(imd)/signin">
        <ImdSigninPage />
      </Route>
      <Route exact path="/:company(imd)/cart">
        <ImdCartPage />
      </Route>
      {/* Wildflowers */}
      <Route exact path="/:company(wildflowers)">
        <WfLandingPage />
      </Route>
      <Route exact path="/:company(wildflowers)/signin">
        <WfSigninPage />
      </Route>
      <Route exact path="/:company(wildflowers)/cart">
        <WfCartPage />
      </Route>
      {/* Miindset */}
      <Route exact path="/:company(miindset)">
        <MsLandingPage />
      </Route>
      <Route exact path="/:company(miindset)/signup">
        <MsSignupPage />
      </Route>
      <Route exact path="/:company(miindset)/signin">
        <MsSigninPage />
      </Route>
      <Route exact path="/:company(miindset)/cart">
        <MsCartPage />
      </Route>
      {/* Genuine-tea */}
      <Route exact path="/:company(genuinetea)">
        <GtLandingPage />
      </Route>
      <Route exact path="/:company(genuinetea)/signup">
        <GtSignupPage />
      </Route>
      <Route exact path="/:company(genuinetea)/signin">
        <GtSigninPage />
      </Route>
      <Route exact path="/:company(genuinetea)/cart">
        <GtCartPage />
      </Route>
      {/* CVMPC */}
      <Route exact path="/:company(cvmpc)">
        <CVMPCLandingPage />
      </Route>
      <Route exact path="/:company(cvmpc)/signup">
        <CVMPCSignupPage />
      </Route>
      <Route exact path="/:company(cvmpc)/signin">
        <CVMPCSigninPage />
      </Route>
      <Route exact path="/:company(cvmpc)/cart">
        <CVMPCCartPage />
      </Route>
      {/* UOFT */}
      <Route exact path="/:company(uoft)">
        <UoftLandingPage />
      </Route>
      <Route exact path="/:company(uoft)/signup">
        <UoftSignupPage />
      </Route>
      <Route exact path="/:company(uoft)/signin">
        <UoftSigninPage />
      </Route>
      <Route exact path="/:company(uoft)/cart">
        <UoftCartPage />
      </Route>
      <Route exact path="/:company(uoft)/checkout">
        <UoftCheckoutPage />
      </Route>
      <Route exact path="/:company(uoft)/success">
        <UoftSuccessPage />
      </Route>
      {/* RHYTHMEDIC */}
      <Route exact path="/:company(rhythmedic)">
        <RhythmedicLandingPage />
      </Route>
      <Route exact path="/:company(rhythmedic)/signup">
        <RhythmedicSignupPage />
      </Route>
      <Route exact path="/:company(rhythmedic)/signin">
        <RhythmedicSigninPage />
      </Route>
      <Route exact path="/:company(rhythmedic)/cart">
        <RhythmedicCartPage />
      </Route>
      <Route exact path="/:company(rhythmedic)/checkout">
        <RhythmedicCheckoutPage />
      </Route>
      <Route exact path="/:company(rhythmedic)/success">
        <RhythmedicSuccessPage />
      </Route>
      {/* Windsor */}
      <Route exact path="/:company(windsor)">
        <WindsorLandingPage />
      </Route>
      <Route exact path="/:company(windsor)/signup">
        <WindsorSignupPage />
      </Route>
      <Route exact path="/:company(windsor)/signin">
        <WindsorSigninPage />
      </Route>
      <Route exact path="/:company(windsor)/cart">
        <WindsorCartPage />
      </Route>
      <Route exact path="/:company(windsor)/checkout">
        <WindsorCheckoutPage />
      </Route>
      <Route exact path="/:company(windsor)/success">
        <WindsorSuccessPage />
      </Route>
      <Route exact path="/:company(seamless)">
        <SeamlessLandingPage />
      </Route>
      <Route exact path="/:company(seamless)/signup">
        <SeamlessSignupPage />
      </Route>
      <Route exact path="/:company(seamless)/signin">
        <SeamlessSigninPage />
      </Route>
      <Route exact path="/:company(seamless)/cart">
        <SeamlessCartPage />
      </Route>
      <Route exact path="/:company(seamless)/checkout">
        <SeamlessCheckoutPage />
      </Route>
      <Route exact path="/:company(seamless)/success">
        <SeamlessSuccessPage />
      </Route>
      {/* Glocoach */}
      <Route exact path="/:company(glocoach)">
        <GlocoachLandingPage />
      </Route>
      <Route exact path="/:company(glocoach)/signup">
        <GlocoachSignupPage />
      </Route>
      <Route exact path="/:company(glocoach)/signin">
        <GlocoachSigninPage />
      </Route>
      <Route exact path="/:company(glocoach)/cart">
        <GlocoachCartPage />
      </Route>
      <Route exact path="/:company(glocoach)/checkout">
        <GlocoachCheckoutPage />
      </Route>
      <Route exact path="/:company(glocoach)/success">
        <GlocoachSuccessPage />
      </Route>
      {/* Eclectic */}
      <Route exact path="/:company(eclectic)">
        <EclecticLandingPage />
      </Route>
      <Route exact path="/:company(eclectic)/signup">
        <EclecticSignupPage />
      </Route>
      <Route exact path="/:company(eclectic)/signin">
        <EclecticSigninPage />
      </Route>
      <Route exact path="/:company(eclectic)/cart">
        <EclecticCartPage />
      </Route>
      <Route exact path="/:company(eclectic)/checkout">
        <EclecticCheckoutPage />
      </Route>
      <Route exact path="/:company(eclectic)/success">
        <EclecticSuccessPage />
      </Route>
      {/* Mindfulnessworks */}
      <Route exact path="/:company(mindfulnessworks)">
        <MindfulnessWorksLandingPage />
      </Route>
      <Route exact path="/:company(mindfulnessworks)/signup">
        <MindfulnessWorksSignupPage />
      </Route>
      <Route exact path="/:company(mindfulnessworks)/signin">
        <MindfulnessWorksSigninPage />
      </Route>
      <Route exact path="/:company(mindfulnessworks)/cart">
        <MindfulnessWorksCartPage />
      </Route>
      {/* League */}
      <Route exact path="/:company(league)">
        <LeagueLandingPage />
      </Route>
      <Route exact path="/:company(league)/signup">
        <LeagueSignupPage />
      </Route>
      <Route exact path="/:company(league)/signin">
        <LeagueSigninPage />
      </Route>
      <Route exact path="/:company(league)/cart">
        <LeagueCartPage />
      </Route>
      <Route exact path="/:company(league)/checkout">
        <LeagueCheckoutPage />
      </Route>
      <Route exact path="/:company(league)/success">
        <LeagueSuccessPage />
      </Route>
      {/* SOSV */}
      <Route exact path="/:company(sosv)">
        <SosvLandingPage />
      </Route>
      <Route exact path="/:company(sosv)/signup">
        <SosvSignupPage />
      </Route>
      <Route exact path="/:company(sosv)/signin">
        <SosvSigninPage />
      </Route>
      <Route exact path="/:company(sosv)/cart">
        <SosvCartPage />
      </Route>
      <Route exact path="/:company(sosv)/checkout">
        <SosvCheckoutPage />
      </Route>
      <Route exact path="/:company(sosv)/success">
        <SosvSuccessPage />
      </Route>
      {/* Newcampus */}
      <Route exact path="/:company(newcampus)">
        <NewcampusLandingPage />
      </Route>
      <Route exact path="/:company(newcampus)/signup">
        <NewcampusSignupPage />
      </Route>
      <Route exact path="/:company(newcampus)/signin">
        <NewcampusSigninPage />
      </Route>
      <Route exact path="/:company(newcampus)/cart">
        <NewcampusCartPage />
      </Route>
      <Route exact path="/:company(newcampus)/checkout">
        <NewcampusCheckoutPage />
      </Route>
      <Route exact path="/:company(newcampus)/success">
        <NewcampusSuccessPage />
      </Route>
      {/* Achtsamengagiert */}
      <Route exact path="/:company(achtsamengagiert)">
        <AchtsamengagiertLandingPage />
      </Route>
      <Route exact path="/:company(achtsamengagiert)/signup">
        <AchtsamengagiertSignupPage />
      </Route>
      <Route exact path="/:company(achtsamengagiert)/signin">
        <AchtsamengagiertSigninPage />
      </Route>
      <Route exact path="/:company(achtsamengagiert)/cart">
        <AchtsamengagiertCartPage />
      </Route>
      <Route exact path="/:company(achtsamengagiert)/checkout">
        <AchtsamengagiertCheckoutPage />
      </Route>
      <Route exact path="/:company(achtsamengagiert)/success">
        <AchtsamengagiertSuccessPage />
      </Route>
      {/* Gan */}
      <Route exact path="/:company(gan)">
        <GanLandingPage />
      </Route>
      <Route exact path="/:company(gan)/signup">
        <GanSignupPage />
      </Route>
      <Route exact path="/:company(gan)/signin">
        <GanSigninPage />
      </Route>
      <Route exact path="/:company(gan)/cart">
        <GanCartPage />
      </Route>
      <Route exact path="/:company(gan)/checkout">
        <GanCheckoutPage />
      </Route>
      <Route exact path="/:company(gan)/success">
        <GanSuccessPage />
      </Route>
      {/* Wework */}
      <Route exact path="/:company(wework)">
        <WeworkLandingPage />
      </Route>
      <Route exact path="/:company(wework)/signup">
        <WeworkSignupPage />
      </Route>
      <Route exact path="/:company(wework)/signin">
        <WeworkSigninPage />
      </Route>
      <Route exact path="/:company(wework)/cart">
        <WeworkCartPage />
      </Route>
      <Route exact path="/:company(wework)/checkout">
        <WeworkCheckoutPage />
      </Route>
      <Route exact path="/:company(wework)/success">
        <WeworkSuccessPage />
      </Route>
      {/* RST */}
      <Route exact path="/:company(rst)">
        <RstLandingPage />
      </Route>
      <Route exact path="/:company(rst)/signup">
        <RstSignupPage />
      </Route>
      <Route exact path="/:company(rst)/signin">
        <RstSigninPage />
      </Route>
      <Route exact path="/:company(rst)/cart">
        <RstCartPage />
      </Route>
      <Route exact path="/:company(rst)/checkout">
        <RstCheckoutPage />
      </Route>
      <Route exact path="/:company(rst)/success">
        <RstSuccessPage />
      </Route>
      {/* Carevoice */}
      <Route exact path="/:company(carevoice)">
        <CarevoiceLandingPage />
      </Route>
      <Route exact path="/:company(carevoice)/signup">
        <CarevoiceSignupPage />
      </Route>
      <Route exact path="/:company(carevoice)/signin">
        <CarevoiceSigninPage />
      </Route>
      <Route exact path="/:company(carevoice)/cart">
        <CarevoiceCartPage />
      </Route>
      {/* MPC */}
      <Route exact path="/:company(mpc)">
        <MPCLandingPage />
      </Route>
      <Route exact path="/:company(mpc)/signup">
        <MPCSignupPage />
      </Route>
      <Route exact path="/:company(mpc)/signin">
        <MPCSigninPage />
      </Route>
      <Route exact path="/:company(mpc)/cart">
        <MPCCartPage />
      </Route>
      {/* CHEOUBC */}
      <Route exact path="/:company(cheoubc)">
        <CULandingPage />
      </Route>
      <Route exact path="/:company(cheoubc)/signup">
        <CUSignupPage />
      </Route>
      <Route exact path="/:company(cheoubc)/signin">
        <CUSigninPage />
      </Route>
      <Route exact path="/:company(cheoubc)/cart">
        <CUCartPage />
      </Route>
      {/* IBM */}
      <Route exact path="/:company(ibm)">
        <IBMLandingPage />
      </Route>
      <Route exact path="/:company(ibm)/signup">
        <IBMSignupPage />
      </Route>
      <Route exact path="/:company(ibm)/signin">
        <IBMSigninPage />
      </Route>
      <Route exact path="/:company(ibm)/cart">
        <IBMCartPage />
      </Route>
      {/* CAMH */}
      <Route exact path="/:company(camh)">
        <CAMHLandingPage />
      </Route>
      <Route exact path="/:company(camh)/signup">
        <CAMHSignupPage />
      </Route>
      <Route exact path="/:company(camh)/signin">
        <CAMHSigninPage />
      </Route>
      <Route exact path="/:company(camh)/cart">
        <CAMHCartPage />
      </Route>
      {/* PC CT */}
      <Route exact path="/:company(pcct)">
        <PCCTLandingPage />
      </Route>
      <Route exact path="/:company(pcct)/signup">
        <PCCTSignupPage />
      </Route>
      <Route exact path="/:company(pcct)/signin">
        <PCCTSigninPage />
      </Route>
      <Route exact path="/:company(pcct)/cart">
        <PCCTCartPage />
      </Route>
      {/* AppScript */}
      <Route exact path="/:company(appscript)">
        <ASLandingPage />
      </Route>
      <Route exact path="/:company(appscript)/signup">
        <ASSignupPage />
      </Route>
      <Route exact path="/:company(appscript)/signin">
        <ASSigninPage />
      </Route>
      <Route exact path="/:company(appscript)/cart">
        <ASCartPage />
      </Route>
      <Route exact path="/:company(appscript)/checkout">
        <ASCheckoutPage />
      </Route>
      <Route exact path="/:company(appscript)/success">
        <ASSuccessPage />
      </Route>
      {/* Buisconsult */}
      <Route exact path="/:company(buisconsult)">
        <BuisconsultLandingPage />
      </Route>
      <Route exact path="/:company(buisconsult)/signup">
        <BuisconsultSignupPage />
      </Route>
      <Route exact path="/:company(buisconsult)/signin">
        <BuisconsultSigninPage />
      </Route>
      <Route exact path="/:company(buisconsult)/cart">
        <BuisconsultCartPage />
      </Route>
      <Route exact path="/:company(buisconsult)/checkout">
        <BuisconsultCheckoutPage />
      </Route>
      <Route exact path="/:company(buisconsult)/success">
        <BuisconsultSuccessPage />
      </Route>
      {/* The Math Guru */}
      <Route exact path="/:company(mathguru)">
        <MGLandingPage />
      </Route>
      <Route exact path="/:company(mathguru)/signup">
        <MGSignupPage />
      </Route>
      <Route exact path="/:company(mathguru)/signin">
        <MGSigninPage />
      </Route>
      <Route exact path="/:company(mathguru)/cart">
        <MGCartPage />
      </Route>
      <Route exact path="/:company(mathguru)/checkout">
        <MGCheckoutPage />
      </Route>
      <Route exact path="/:company(mathguru)/success">
        <MGSuccessPage />
      </Route>
      {/* PC Health */}
      <Route exact path="/:company(pchealth)">
        <PCHLandingPage />
      </Route>
      <Route exact path="/:company(pchealth)/signup">
        <PCHSignupPage />
      </Route>
      <Route exact path="/:company(pchealth)/signin">
        <PCHSigninPage />
      </Route>
      <Route exact path="/:company(pchealth)/cart">
        <PCHCartPage />
      </Route>
      <Route exact path="/:company(pchealth)/checkout">
        <PCHCheckoutPage />
      </Route>
      <Route exact path="/:company(pchealth)/success">
        <PCHSuccessPage />
      </Route>
      {/* Synaptichacks */}
      <Route exact path="/:company(synaptichacks)">
        <SHLandingPage />
      </Route>
      <Route exact path="/:company(synaptichacks)/signup">
        <SHSignupPage />
      </Route>
      <Route exact path="/:company(synaptichacks)/signin">
        <SHSigninPage />
      </Route>
      <Route exact path="/:company(synaptichacks)/cart">
        <SHCartPage />
      </Route>
      <Route exact path="/:company(synaptichacks)/checkout">
        <SHCheckoutPage />
      </Route>
      <Route exact path="/:company(synaptichacks)/success">
        <SHSuccessPage />
      </Route>
      {/* AmDTx */}
      <Route exact path="/:company(amdtx)">
        <AMLandingPage />
      </Route>
      <Route exact path="/:company(amdtx)/signup">
        <AMSignupPage />
      </Route>
      <Route exact path="/:company(amdtx)/signin">
        <AMSigninPage />
      </Route>
      <Route exact path="/:company(amdtx)/cart">
        <AMCartPage />
      </Route>
      <Route exact path="/:company(amdtx)/checkout">
        <AMCheckoutPage />
      </Route>
      <Route exact path="/:company(amdtx)/success">
        <AMSuccessPage />
      </Route>
      {/* Mindful Junkie */}
      <Route exact path="/:company(mindfuljunkie)">
        <MJLandingPage />
      </Route>
      <Route exact path="/:company(mindfuljunkie)/signup">
        <MJSignupPage />
      </Route>
      <Route exact path="/:company(mindfuljunkie)/signin">
        <MJSigninPage />
      </Route>
      <Route exact path="/:company(mindfuljunkie)/cart">
        <MJCartPage />
      </Route>
      <Route exact path="/:company(mindfuljunkie)/checkout">
        <MJCheckoutPage />
      </Route>
      <Route exact path="/:company(mindfuljunkie)/success">
        <MJSuccessPage />
      </Route>
      {/* Manon Breton */}
      <Route exact path="/:company(manonbreton)">
        <MBLandingPage />
      </Route>
      <Route exact path="/:company(manonbreton)/signup">
        <MBSignupPage />
      </Route>
      <Route exact path="/:company(manonbreton)/signin">
        <MBSigninPage />
      </Route>
      <Route exact path="/:company(manonbreton)/cart">
        <MBCartPage />
      </Route>
      <Route exact path="/:company(manonbreton)/checkout">
        <MBCheckoutPage />
      </Route>
      <Route exact path="/:company(manonbreton)/success">
        <MBSuccessPage />
      </Route>
      {/* Julien Lacaille */}
      <Route exact path="/:company(julienlacaille)">
        <JLLandingPage />
      </Route>
      <Route exact path="/:company(julienlacaille)/signup">
        <JLSignupPage />
      </Route>
      <Route exact path="/:company(julienlacaille)/signin">
        <JLSigninPage />
      </Route>
      <Route exact path="/:company(julienlacaille)/cart">
        <JLCartPage />
      </Route>
      {/* LifeDrop */}
      <Route exact path="/:company(lifedrop)">
        <LDLandingPage />
      </Route>
      <Route exact path="/:company(lifedrop)/signup">
        <LDSignupPage />
      </Route>
      <Route exact path="/:company(lifedrop)/signin">
        <LDSigninPage />
      </Route>
      <Route exact path="/:company(lifedrop)/cart">
        <LDCartPage />
      </Route>
      {/* Gspace */}
      <Route exact path="/:company(gspace)">
        <GSLandingPage />
      </Route>
      <Route exact path="/:company(gspace)/signup">
        <GSSignupPage />
      </Route>
      <Route exact path="/:company(gspace)/signin">
        <GSSigninPage />
      </Route>
      <Route exact path="/:company(gspace)/cart">
        <GSCartPage />
      </Route>
      <Route exact path="/:company(gspace)/checkout">
        <GSCheckoutPage />
      </Route>
      <Route exact path="/:company(gspace)/success">
        <GSSuccessPage />
      </Route>
      {/* Lumino */}
      <Route exact path="/:company(lumino)">
        <LLandingPage />
      </Route>
      <Route exact path="/:company(lumino)/signup">
        <LSignupPage />
      </Route>
      <Route exact path="/:company(lumino)/signin">
        <LSigninPage />
      </Route>
      <Route exact path="/:company(lumino)/cart">
        <LCartPage />
      </Route>
      <Route exact path="/:company(lumino)/checkout">
        <LCheckoutPage />
      </Route>
      <Route exact path="/:company(lumino)/success">
        <LSuccessPage />
      </Route>
      {/* Sunlife */}
      <Route exact path="/:company(sunlife)">
        <SLLandingPage />
      </Route>
      <Route exact path="/:company(sunlife)/signup">
        <SLSignupPage />
      </Route>
      <Route exact path="/:company(sunlife)/signin">
        <SLSigninPage />
      </Route>
      <Route exact path="/:company(sunlife)/cart">
        <SLCartPage />
      </Route>
      <Route exact path="/:company(sunlife)/checkout">
        <SLCheckoutPage />
      </Route>
      <Route exact path="/:company(sunlife)/success">
        <SLSuccessPage />
      </Route>
      {/* GSC */}
      <Route exact path="/:company(gsc)">
        <GSCLandingPage />
      </Route>
      <Route exact path="/:company(gsc)/signup">
        <GSCSignupPage />
      </Route>
      <Route exact path="/:company(gsc)/signin">
        <GSCSigninPage />
      </Route>
      <Route exact path="/:company(gsc)/cart">
        <GSCCartPage />
      </Route>
      <Route exact path="/:company(gsc)/checkout">
        <GSCCheckoutPage />
      </Route>
      <Route exact path="/:company(gsc)/success">
        <GSCSuccessPage />
      </Route>
      {/* SNIC */}
      <Route exact path="/:company(snic)">
        <SNICLandingPage />
      </Route>
      <Route exact path="/:company(snic)/signup">
        <SNICSignupPage />
      </Route>
      <Route exact path="/:company(snic)/signin">
        <SNICSigninPage />
      </Route>
      <Route exact path="/:company(snic)/cart">
        <SNICCartPage />
      </Route>
      <Route exact path="/:company(snic)/checkout">
        <SNICCheckoutPage />
      </Route>
      <Route exact path="/:company(snic)/success">
        <SNICSuccessPage />
      </Route>
      {/* <Route exact path="/checkout">
        <CheckoutPage />
      </Route> */}
      {/* General */}
      <Route exact path="/google/close">
        <GoogleCloseBrowser />
      </Route>

      <Route exact path="/:company(mbcs_ca)">
        <MbcsLandingPage />
      </Route>
      <Route exact path="/:company(mbcs_ca)/signup">
        <MbcsSignupPage />
      </Route>
      <Route exact path="/:company(mbcs_ca)/signin">
        <MbcsSigninPage />
      </Route>
      <Route exact path="/:company(mbcs_ca)/cart">
        <MbcsCartPage />
      </Route>
      <Route exact path="/:company(mbcs_ca)/checkout">
        <MbcsCheckoutPage />
      </Route>
      <Route exact path="/:company(mbcs_ca)/success">
        <MbcsSuccessPage />
      </Route>

      <Route exact path="/:company(cheo1td)">
        <MbcsLandingPage />
      </Route>
      <Route exact path="/:company(cheo1td)/signup">
        <MbcsSignupPage />
      </Route>
      <Route exact path="/:company(cheo1td)/signin">
        <MbcsSigninPage />
      </Route>
      <Route exact path="/:company(cheo1td)/cart">
        <MbcsCartPage />
      </Route>
      <Route exact path="/:company(cheo1td)/checkout">
        <MbcsCheckoutPage />
      </Route>
      <Route exact path="/:company(cheo1td)/success">
        <MbcsSuccessPage />
      </Route>

      <Route exact path="/:company(cheot1d)">
        <Cheot1dLandingPage />
      </Route>
      <Route exact path="/:company(cheot1d)/signup">
        <Cheot1dSignupPage />
      </Route>
      <Route exact path="/:company(cheot1d)/signin">
        <Cheot1dSigninPage />
      </Route>
      <Route exact path="/:company(cheot1d)/cart">
        <Cheot1dCartPage />
      </Route>

      <Route exact path="/:company(mskcc)">
        <MskccLandingPage />
      </Route>
      <Route exact path="/:company(mskcc)/signup">
        <MskccSignupPage />
      </Route>
      <Route exact path="/:company(mskcc)/signin">
        <MskccSigninPage />
      </Route>
      <Route exact path="/:company(mskcc)/cart">
        <MskccCartPage />
      </Route>

      <Route exact path="/:company(ptbiwest)">
        <PtbiWestLandingPage />
      </Route>
      <Route exact path="/:company(ptbiwest)/signup">
        <PtbiWestSignupPage />
      </Route>
      <Route exact path="/:company(ptbiwest)/signin">
        <PtbiWestSigninPage />
      </Route>
      <Route exact path="/:company(ptbiwest)/cart">
        <PtbiWestCartPage />
      </Route>

      <Route exact path="/:company(uw)">
        <ViplabLandingPage />
      </Route>
      <Route exact path="/:company(uw)/signup">
        <ViplabSignupPage />
      </Route>
      <Route exact path="/:company(uw)/signin">
        <ViplabSigninPage />
      </Route>
      <Route exact path="/:company(uw)/cart">
        <ViplabCartPage />
      </Route>

      <Route exact path="/:company(groundzero)">
        <GroundzeroLandingPage />
      </Route>
      <Route exact path="/:company(groundzero)/signup">
        <GroundzeroSignupPage />
      </Route>
      <Route exact path="/:company(groundzero)/signin">
        <GroundzeroSigninPage />
      </Route>
      <Route exact path="/:company(groundzero)/cart">
        <GroundzeroCartPage />
      </Route>

      <Route exact path="/:company(obi)">
        <OBILandingPage />
      </Route>
      <Route exact path="/:company(obi)/signup">
        <OBISignupPage />
      </Route>
      <Route exact path="/:company(obi)/signin">
        <OBISigninPage />
      </Route>
      <Route exact path="/:company(obi)/cart">
        <OBICartPage />
      </Route>

      <Route exact path="/:company(mstbi)">
        <MstbiLandingPage />
      </Route>
      <Route exact path="/:company(mstbi)/signup">
        <MstbiSignupPage />
      </Route>
      <Route exact path="/:company(mstbi)/signin">
        <MstbiSigninPage />
      </Route>
      <Route exact path="/:company(mstbi)/cart">
        <MstbiCartPage />
      </Route>


      <Route exact path="/apidata">
        <ApiDataPage />
      </Route>

      <Route exact path="/apidata-detail">
        <ApiDataDetailPage />
      </Route>

      <Route exact path="/change-password">
        <ChangePasswordPage />
      </Route>
    </Switch>
  );
}

export default App;
